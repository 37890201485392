import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import "./contacts.styles.scss";
import headerImage from "../../images/header.jpg";

export default function Services() {
  return (
    <div className="contacts">
      <Container>
        <CardGroup>
          <Row xs={1} sm={1} md={2} className="g-4">
            <Col>
              <div className="contacts_container">
                <div className="contacts_box">
                  <h5>Susisiekite dėl užsakymo</h5>
                  <form>
                    <label>Jūsų Vardas</label>
                    <input
                      className="contacts_input"
                      type="text"
                      name="user_name"
                    />
                    <label>El. paštas</label>
                    <input
                      className="contacts_input"
                      type="email"
                      name="user_email"
                    />
                    <label>Žinutė</label>
                    <textarea className="contacts_input" name="message" />
                    <input className="button" type="submit" value="Send" />
                  </form>
                </div>
              </div>
            </Col>
            <Col>
              <div className="contacts_working_hours">
                <div>
                  <h6>Tel: +37102553633</h6>
                  <h6>El. paštas: nezinau@gmail.com</h6>
                </div>
                <hr></hr>
                <Row xs={2} sm={2} md={2}>
                  <Col>
                    <h6>Kunas laisves al 55</h6>
                    <p>Pirmadienis: Nedirbame</p>
                    <p>Antradienis: 8:00 - 17:00 val</p>
                    <p>Trečiadienis: 8:00 - 17:00 val</p>
                    <p> Ketvirtadienis: 8:00 - 17:00 val</p>
                    <p>Penktadienis: 8:00 - 17:00 val</p>
                    <p>Šeštadienis: Nedirbama</p>
                    <p> Sekmadienis: Nedirbama</p>
                  </Col>
                  <Col>
                    <h6>Kunas laisves al 55</h6>
                    <p>Pirmadienis: Nedirbama</p>
                    <p>Antradienis: 8:00 - 17:00 val</p>
                    <p>Trečiadienis: 8:00 - 17:00 val</p>
                    <p> Ketvirtadienis: 8:00 - 17:00 val</p>
                    <p>Penktadienis: 8:00 - 17:00 val</p>
                    <p>Šeštadienis: Nedirbama</p>
                    <p> Sekmadienis: Nedirbama</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="iframe_container">
            <h6>Kauno m laisves al</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7710.316420032322!2d23.86835350750138!3d54.932916453604626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e71f4381ecff4d%3A0xe62f9cf12aef2fa1!2sLidl!5e0!3m2!1slt!2slt!4v1678954990280!5m2!1slt!2slt"
              width="100%"
              height="250"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="iframe_container">
            <h6>Kauno m laisves al</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7710.316420032322!2d23.86835350750138!3d54.932916453604626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e71f4381ecff4d%3A0xe62f9cf12aef2fa1!2sLidl!5e0!3m2!1slt!2slt!4v1678954990280!5m2!1slt!2slt"
              width="100%"
              height="250"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
}
