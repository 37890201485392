import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import "./productlist.styles.scss";
import headerImage from "../../images/header.jpg";
import productsImage from "../../images/products.jpg";

export default function ProductList() {
  return (
    <div className="product_image_container">
      <Container className="products_container">
        <CardGroup>
          <Row xs={1} sm={2} md={4} className="g-4">
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">Brokolis</Card.Title>
                  <hr />
                  <Card.Text className="description">1kg 25€</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">Brokolis</Card.Title>
                  <hr />
                  <Card.Text className="description">1kg 25€</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">Brokolis</Card.Title>
                  <hr />
                  <Card.Text className="description">1kg 25€</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">Brokolis</Card.Title>
                  <hr />
                  <Card.Text className="description">1kg 25€</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </CardGroup>
        <div className="button_container">
          <button className="products_button">Daugiau prekiu</button>
        </div>
      </Container>
    </div>
  );
}
