import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import "./services.styles.scss";
import headerImage from "../../images/header.jpg";

export default function Services() {
  return (
    <div className="services_image_container">
      <Container className="services_container">
        <CardGroup>
          <Row xs={1} sm={1} md={3} className="g-4">
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">Pristatymas į namus</Card.Title>
                  <hr />
                  <Card.Text className="description">
                    Gaukite šviežių ir vietinių daržovių pristatymą į namus
                    Kauno mieste naudodamiesi mūsų patogia daržovių pristatymo
                    paslauga. Mėgaukitės patogumu apsipirkti iš savo namų, tuo
                    pačiu palaikydami vietinius ūkininkus ir valgydami sveikas,
                    maistingas daržoves.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">
                    Pristatymas restoranams
                  </Card.Title>
                  <hr />
                  <Card.Text className="description">
                    Užtikrinkite, kad jūsų restoranas patiektų šviežiausias ir
                    aukščiausios kokybės daržoves naudodamiesi mūsų patikima
                    daržovių pristatymo paslauga Kauno mieste. Mes siūlome
                    įvairias vietinio ūkininkavimo produkcijas, kad atitiktume
                    jūsų restorano unikalius poreikius, viską pristatome laiku
                    ir patogiai.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <img src={headerImage} className="img-thumbnail" alt="..." />
                <Card.Body>
                  <Card.Title className="title">
                    Individualus užsakymas
                  </Card.Title>
                  <hr />
                  <Card.Text className="description">
                    Sukurkite savo asmeninį daržovių užsakymą, pritaikytą jūsų
                    individualiems poreikiams. Pasirinkite iš įvairių šviežių ir
                    vietinių produktų ir patogiai juos pristatykite tiesiai į
                    savo namus ar verslą.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </CardGroup>
      </Container>
    </div>
  );
}
