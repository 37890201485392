import React from "react";
import "./header.styles.scss";
import headerImage from "../../images/header.jpg";

export default function Header() {
  return (
    <header>
      <img className="header_image" src={headerImage} />
      <div className="header_container">
        <div className="header_text">
          <p>
            Močiutės ūkis yra ekologiškas ūkis, specializuojantis auginti
            įvairias daržoves ir vaisius be sintetinių pesticidų, trąšų ar GMO.
            Ūkis naudoja tvarias žemės ūkio praktikas, siekdamas skatinti dirvos
            sveikatą ir biologinę įvairovę, ir siekia teikti šviežius ir
            maistingus produktus vietinėms bendruomenėms.
          </p>
        </div>
        <button className="header_button">Susisiek dabar</button>
      </div>
    </header>
  );
}
