import React from "react";
import "./footer.styles.scss";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer>
      <div className="footer_container">
        <p>Visos teisės priklauso © Močiutės ūkis {year}</p>
        <div className="footer_box">
          <p className="copyright">
            Website created by :{" "}
            <a target="_blank" href="https://www.deivdev.com" className="link">
              Deiv-Dev
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
