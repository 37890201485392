import React from "react";
import "./about.styles.scss";

export default function About() {
  return (
    <>
      <div className="about_container">
        <div className="about_box">
          <p className="about_text">
            Sveiki atvykę į močiutės ūkį! Jau daugelį metų teikiame vietinėje
            bendruomenėje augintus daržoves ir vaisius. Mūsų ūkis yra mažas,
            šeimos valdomas ir mes didžiuojamės teikdami aukštos kokybės
            produkciją, kuri yra švieži, sveika ir skani.
            <br></br>
            <br></br>
            Mes manome, kad vietinės maisto produktų vartojimo svarba yra ne tik
            dėl skonio ir mitybos naudos, bet ir dėl aplinkos bei ekonominių
            naudų, kurios kyla remiant vietinius ūkininkus. Todėl mes
            įsipareigojame auginti savo produkciją tiesiog čia Kauno rajone,
            naudodami tvarias ūkininkavimo praktikas, kurios mažina mūsų poveikį
            aplinkai.
            <br></br>
            <br></br>
            Jei ieškote šviežių, vietinių produktų, neieškokite toliau nei
            močiutės ūkis. Mes didžiuojamės būdami šios bendruomenės dalimi ir
            laukiame, kad galėtume Jus aptarnauti dar daugelį metų!
          </p>
        </div>
      </div>
    </>
  );
}
