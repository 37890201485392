import React from "react";
import Header from "../components/header/Header.components";
import ProductList from "../components/products/ProductList.components";
import Services from "../components/services/Services.components";
import About from "../components/about/About.components";
// import Skills from "../components/skills/Skills.components";
// import Work from "../components/work/Work.components";
import Contacts from "../components/contacts/Contacts.components";
import Footer from "../components/footer/Footer.components";
// import "./homepage.scss";
import Navigarion from "../components/navigarion/Navigarion.components";

export default function Main() {
  return (
    <>
      <Navigarion />
      <Header />
      <ProductList />
      <Services />
      <Contacts />
      <About />
      <Footer />
    </>
  );
}
